/**
 * @module Header
 * @description Site Header
 */
import Head from 'next/head';

// Tracking & Scripts
import { GTMScript } from '@/resources/GTM';
import { AdobeSnippet } from '@/resources/Adobe';
import SchemaMarkup from '@/resources/SchemaMarkup';
import CookieConsent from '@/resources/CookieConsent';

// Components
import StickyNav from '@/blocks/StickyNav';
import SiteNavigation from '@/blocks/SiteNavigation';

// Helpers
import { getAsset } from '@/js/utils/helpers';

// Cohesion
import MonarchFallback from '@/blocks/MonarchFallback';
import { MonarchPreampPlacement } from '@redventures/cohesion-utils-react';
import { getBaseAssetName } from '@/js/utils/helpers';

/**
 * @function Header
 * @description Default Header
 */
export const Header = () => {
  const siteTitle = 'Frontier.com';
  const siteUrl = '/';
  const siteDescription = 'Enjoy fiber internet, TV & phone services from Frontier. Explore the best Internet, TV, and phone packages and deals we offer. More digital solutions available.';
  const faviconPath = getAsset( '/img/favicons/' );

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover' />
        <meta name="format-detection" content="telephone=no" />

        { /* Image Preconnects */ }
        <link rel="preconnect" href={ process.env.imagePath } />

        { /* PWA Info */ }
        <meta name='application-name' content={ siteTitle } />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar-style' content='default' />
        <meta name='apple-mobile-web-app-title' content={ siteTitle } />
        <meta name='mobile-web-app-capable' content='yes' />
        <meta name='msapplication-config' content={ `${ faviconPath }browserconfig.xml` } />
        <meta name='msapplication-tap-highlight' content='no' />
        <meta name="theme-color" content="#ffffff" />

        <link rel='apple-touch-icon' href={ `${ faviconPath }apple-touch-icon.png` } />
        <link rel='apple-touch-icon' sizes='152x152' href={ `${ faviconPath }apple-touch-icon-ipad.png` } />
        <link rel='apple-touch-icon' sizes='180x180' href={ `${ faviconPath }apple-touch-icon-iphone-retina.png` } />
        <link rel='apple-touch-icon' sizes='167x167' href={ `${ faviconPath }apple-touch-icon-ipad-retina.png` } />

        <link rel='icon' type='image/png' sizes='32x32' href={ `${ faviconPath }favicon-32x32.png` } />
        <link rel='icon' type='image/png' sizes='16x16' href={ `${ faviconPath }favicon-16x16.png` } />
        <link rel='shortcut icon' href={ `${ faviconPath }favicon.ico` } />

        <link rel='manifest' href={ `${ getAsset( '/manifest.json' ) }` } type="application/manifest+json"/>
        <link rel='mask-icon' href={ `${ faviconPath }safari-pinned-tab.svg` } color="#df4841" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name='twitter:url' content={ siteUrl } />
        <meta name='twitter:title' content={ siteTitle } />
        <meta name='twitter:description' content={ siteDescription } />
        <meta name='twitter:image' content={ `${ faviconPath }android-chrome-192x192.png` } />
        <meta name='twitter:creator' content='@FrontierCorp' />

        <meta property='og:type' content='website' />
        <meta property="og:title" content="Internet, Phone & TV Service Provider | Frontier.com" />
        <meta property='og:description' content={ siteDescription } />
        <meta property='og:site_name' content={ siteTitle } />
        <meta property='og:url' content={ siteUrl } />
        <meta property='og:image' content={ `${ faviconPath }og-image.jpg` } />
        { /* END PWA Info */ }

        <SchemaMarkup />
      </Head>

      { /* 3rd Party / External Scripts */ }
      <CookieConsent />
      { process.env.environment === 'production' && <GTMScript /> }
      { ( process.env.environment === 'production' || process.env.environment === 'development' ) && <AdobeSnippet /> }

      <header id="masthead" className="site-header" role="banner">
        <a href="#content" className="skip-link screen-reader-text">Skip to content</a>

        <MonarchPreampPlacement placement="6noFYtwuZ2z8wkprQuC4kA" componentId={ getBaseAssetName }
        fallback={ <MonarchFallback height={ [ '0px', '0px', '37.625px' ] } /> } >
          <StickyNav />
        </MonarchPreampPlacement>

        <SiteNavigation />
      </header>{ /* <!-- .site-header --> */ }
    </>
  );
};

export default Header;
